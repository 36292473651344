<template>
  <!-- card -->
  <div
    class="flex flex-col items-center justify-start px-4 sm:px-8 py-2 rounded-xl shadow-xl bg-light card-container"
  >
    <div
      class="grid grid-cols-1 flex-1 w-full py-3 sm:py-6 card-inner-container"
    >
      <!-- video result view -->
      <div
        class="relative flex flex-col items-stretch justify-start col-span-2 lg:col-span-1"
      >
        <!-- heading -->
        <h1
          class="text-left font-display font-bold text-base sm:text-lg md:text-2xl text-dark mb-2"
        >
          Check your inbox, your personalized advertisement has been e-mailed to
          you.
        </h1>
        <!-- caption -->
        <h4 class="text-left font-display font-normal text-xs text-dark">
          Please check your primary email address,
          <b>{{ user && user.email ? user.email : "" }}</b
          >, for the video download link and video preview link. Please also
          check your spam folder and promotions folder for the email.
        </h4>
        <!-- <h4 class="text-left font-display font-normal text-xs text-dark">
          Please also check your spam folder and promotions folder for the email.
        </h4> -->
        <!-- video loading progress -->
        <!-- py-32 md:py-40 -->
        <div
          class="flex flex-row flex-grow items-center justify-center py-10 h-full"
        >
          <transition name="fade-slow" mode="out-in">
            <template v-if="isAPILoading">
              <!-- heroicon - progress loader icon -->
              <svg
                class="relative animate-spin h-12 w-12 mx-auto text-dark progress-circle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </template>
            <template v-else>
              <div
                class="flex flex-col items-center justify-start video-content"
              >
                <!-- image layer (video preview image) -->
                <div
                  class="relative w-full max-w-lg max-h-72 z-0 hover:shadow-hard transition-shadow duration-500 ease-in-out overflow-hidden rounded-xl image-container"
                >
                  <img
                    class="relative w-full transition-opacity duration-500 ease-in-out z-0 rounded-xl overflow-hidden object-cover object-center video-preview-image"
                    :src="user.thumb ?? user.targetVideoPreviewImage"
                  />
                  <!-- masked overlay with hover actions -->
                  <div
                    class="absolute flex flex-row items-center justify-center top-0 left-0 w-full h-full z-10 rounded-xl bg-opacity-60"
                  >
                    <!-- preview video button -->
                    <button
                      class="relative flex flex-col items-center justify-center bg-transparent rounded-sm w-20 h-20 mx-1 text-white hover:text-success transition-colors duration-300 preview-video-button"
                      @click="openPreviewVideoModal()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="w-8 h-8 fill-current"
                      >
                        <path
                          d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                        ></path>
                        <path d="m9 17 8-5-8-5z"></path>
                      </svg>
                      <span
                        class="text-center text-xs font-display font-normal text-current py-1"
                        >Play</span
                      >
                    </button>
                  </div>
                </div>
                <!-- video title -->
                <h6
                  :title="user.targetVideoTitle"
                  class="text-center text-lg sm:text-xl text-dark truncate font-display font-light w-full mt-4 mb-1"
                >
                  {{ user.targetVideoTitle }}
                </h6>
                <div
                  class="flex flex-col md:flex-row items-center justify-center my-6"
                >
                  <!-- copy video link button -->
                  <button
                    class="block bg-secondary rounded-sm w-52 py-2 m-2 transition-all duration-200 shadow-none hover:shadow-soft copy-link-button"
                    @click="goBackToUser"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <!-- boxicon - back arrow icon -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        class="fill-current text-white"
                      >
                        <path
                          d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z"
                        ></path>
                      </svg>
                      <span
                        class="font-display font-bold text-left text-white ml-2"
                      >
                        Back to User Form
                      </span>
                    </div>
                  </button>
                  <!-- copy video link button -->
                  <button
                    class="block bg-ghost-white rounded-sm w-52 py-2 m-2 transition-all duration-200 shadow-none hover:shadow-soft copy-link-button"
                    copyTitle="Video preview link copied"
                    :class="linkCopied ? 'show-tooltip' : ''"
                    @click="copyVideoLink"
                  >
                    <div class="flex flex-row items-center justify-center">
                      <span
                        class="font-display font-bold text-left text-dark mr-2"
                      >
                        Copy Video Link
                      </span>
                      <!-- boxicon - link icon -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style="fill: rgba(0, 0, 0, 1); transform: ; msfilter: "
                      >
                        <path
                          d="M8.465 11.293c1.133-1.133 3.109-1.133 4.242 0l.707.707 1.414-1.414-.707-.707c-.943-.944-2.199-1.465-3.535-1.465s-2.592.521-3.535 1.465L4.929 12a5.008 5.008 0 0 0 0 7.071 4.983 4.983 0 0 0 3.535 1.462A4.982 4.982 0 0 0 12 19.071l.707-.707-1.414-1.414-.707.707a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.122-2.121z"
                        ></path>
                        <path
                          d="m12 4.929-.707.707 1.414 1.414.707-.707a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.122 2.121c-1.133 1.133-3.109 1.133-4.242 0L10.586 12l-1.414 1.414.707.707c.943.944 2.199 1.465 3.535 1.465s2.592-.521 3.535-1.465L19.071 12a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0z"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </template>
          </transition>
        </div>
      </div>
    </div>
    <!-- video preview modal (with fade transition) -->
    <transition name="fade" mode="out-in">
      <template v-if="showVideoPreviewModal">
        <div class="fixed z-40 inset-0 overflow-y-auto">
          <div
            class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block lg:p-0"
          >
            <!-- background overlay -->
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-black opacity-60"></div>
            </div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
              >&#8203;</span
            >
            <!-- modal content -->
            <div
              class="inline-block align-center bg-white rounded-xl overflow-hidden shadow-hard transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full video-preview-modal"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <!-- modal header -->
              <header
                class="absolute top-0 w-full flex flex-row items-center justify-between transition-opacity ease-in-out duration-500 p-2 pl-4"
              >
                <h2
                  class="text-left text-base sm:text-lg font-display font-bold text-white truncate"
                >
                  Preview Video - {{ this.currentPreviewVideoTitle }}
                </h2>
                <button
                  type="button"
                  class="rounded-full p-1 text-white hover:text-prime-gray transition-colors duration-300 focus:outline-none"
                  @click="closePreviewVideoModal"
                >
                  <span class="sr-only">Close Modal</span>
                  <!-- Heroicon name: outline/x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </header>
              <!-- modal inner content -->
              <div
                class="flex flex-col items-start justify-start bg-dark modal-inner-content"
              >
                <video
                  width="100%"
                  height="auto"
                  autoplay="autoplay"
                  controls="controls"
                  class="video-element"
                  :src="currentPreviewVideoURL"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
//store
import { mapGetters } from "vuex";

//services
import { GetSingleUser } from "@/services/user.service";
import backGroundImg from "../assets/img/secondary-main.jpg";

export default {
  name: "ResultVideo",
  data() {
    return {
      user: null,
      isAPILoading: true,
      showVideoPreviewModal: false,
      currentPreviewVideoTitle: null,
      currentPreviewVideoURL: null,
      linkCopied: false,
      campaignNameId: null,
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "users/getUserDetails",
    }),
  },
  async mounted() {
    // background image
    document.getElementById("backgroundImgId").style.background =
      "url(" + `${backGroundImg}`;
    (")");
    document.getElementById("backgroundImgId").style.backgroundSize = "cover";
    // get user details from API
    await this.getUserById(
      this.$route.params.campaignName,
      this.$route.params.videoName
    );
  },
  methods: {
    // populate target videos list
    async getUserById(campaignName, videoName) {
      try {
        this.isAPILoading = true;
        let { data } = await GetSingleUser(campaignName, videoName);
        if (data.code == 200 || data.message == "success") {
          this.user = data.content;
          this.campaignNameId = data.content.idName;
        }
      } catch {
        console.log("ERROR RV01");
      } finally {
        this.isAPILoading = false;
      }
    },
    // preview video modal functions
    openPreviewVideoModal() {
      // make the modal visible
      this.showVideoPreviewModal = true;
      // populate the video details into data parameters to be passed to the modal
      this.currentPreviewVideoTitle = this.user.targetVideoTitle;
      this.currentPreviewVideoURL = this.user.resultVideoDownloadLink;
    },
    closePreviewVideoModal() {
      // hide the modal
      this.showVideoPreviewModal = false;
      // reset the video details
      this.currentPreviewVideoURL = null;
      this.currentPreviewVideoTitle = null;
    },
    copyVideoLink() {
      // Get the url of the video preview link
      // let copyLink = this.user.resultVideoPreviewLink;
      // get current url for now
      let copyLink = window.location.href;
      // change the url to the preview url
      copyLink = copyLink.replace("result", "preview");

      // create a text element to assign and copy the link
      let el = document.createElement("textarea");
      el.value = copyLink;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      // only show popover for three seconds
      this.linkCopied = true;
      // hide after 3 seconds
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    goBackToUser() {
      this.$router.replace(`/${this.campaignNameId}`);
    },
  },
};
</script>

<style scoped>
.card-container .card-inner-container {
  height: 50vh;
}
/* video modal header opacity transition */
.video-preview-modal > header {
  opacity: 0;
  z-index: 10;
}
.video-preview-modal:hover > header {
  opacity: 1;
}
/* video element max height */
.video-element {
  max-height: 85vh;
}
/* class for copy to clipboard button tooltip (add the text in the copyTitle attribute of the button) */
.copy-link-button {
  position: relative;
}
/* Tooltip container button */
.copy-link-button::after {
  content: attr(copyTitle);
  visibility: hidden;
  width: 100%;
  opacity: 0.6;
  font-size: 0.7rem;
  background-color: #333333;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: calc(100% + 0.25rem);
  left: 0;
}
.copy-link-button::before {
  content: " ";
  visibility: hidden;
  position: absolute;
  bottom: -0.25rem; /* At the top of the tooltip */
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem;
  border-style: solid;
  opacity: 0.6;
  border-color: transparent transparent #333333 transparent;
}
/* Show the tooltip text when you the show-tooltip class is assigned */
.copy-link-button.show-tooltip::after,
.copy-link-button.show-tooltip::before {
  visibility: visible;
}
</style>
